import http from "./http";

const variantApi = "/restaurant/menu/variant";
const categoriesApi = "/restaurant/menu/category";
const quantityUnitApi = "/restaurant/menu/quantity/unit";

// Variant API functions
export const addVariant = (data, query = "") => {
  return http.post(`${variantApi}/create${query}`, data);
};

export const getAllVariants = async () => {
  const res = await http.get(`${variantApi}/all`);
  return res;
};

export const deleteVariant = async (query = "") => {
  return http.delete(`${variantApi}/delete/${query}`);
};

// Category API functions
export const addCategory = (data, query = "") => {
  return http.post(`${categoriesApi}/create${query}`, data);
};

export const getAllCategories = async () => {
  const res = await http.get(`${categoriesApi}/all`);
  return res;
};

export const deleteCategory = async (query = "") => {
  return http.delete(`${categoriesApi}/delete/${query}`);
};

// Quantity Unit API functions
export const addQuantityUnit = (data, query = "") => {
  return http.post(`${quantityUnitApi}/create${query}`, data);
};

export const getAllQuantityUnits = async () => {
  const res = await http.get(`${quantityUnitApi}/all`);
  return res;
};

export const deleteQuantityUnit = async (query = "") => {
  return http.delete(`${quantityUnitApi}/delete/${query}`);
};
