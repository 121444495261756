import {
  Avatar,
  Button,
  Flex,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { FaBell, FaUser } from "react-icons/fa";
import { FiLogOut, FiMenu } from "react-icons/fi";
import { MdClose } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { handleNavBar, logout } from "../redux/authSlice";
import { getAllNotifications, markAsRead } from "../services/notificationApi";
import ConfirmationModal from "./ConfirmationModal";
import MyButton from "./MyButton";

const CustomPopup = ({ notifications }) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const markAllAsRead = async () => {
    setLoading(true);
    try {
      const res = await markAsRead();
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="relative inline-block text-left">
      <div>
        <button
          onClick={togglePopup}
          type="button"
          className="text-2xl text-black mr-3 outline-none relative bg-transparent border-none"
          style={{ padding: "0.25rem", cursor: "pointer" }}
        >
          <FaBell />
          <span className="bg-red-500 text-white rounded-full px-1.5 py-0.5 absolute top-0 right-0 -mt-1 -mr-1 text-xs">
            {notifications.filter((n) => !n.isSeen).length}
          </span>
        </button>
      </div>
      {isPopupOpen && (
        <div className="origin-bottom-end absolute right-0 mb-2 w-[70vw] md:w-[50vw] lg:w-[30vw] bg-[#F6F1EE] text-black rounded shadow-lg">
          <div className="p-4">
            <div className="flex justify-between items-center">
              <div className="font-semibold text-lg">Notifications</div>
              <button
                onClick={togglePopup}
                className="p-2 hover:bg-gray-200 rounded-full"
              >
                <MdClose />
              </button>
            </div>
            {/* <div className="bg-[#F6F1EE] h-1 w-5 transform rotate-45 absolute top-0 right-4"></div> */}
            <div className="mt-2 max-h-96 overflow-y-auto no-scrollbar">
              {notifications.map((notification, index) => (
                <div
                  key={index}
                  className={`mb-2 pb-2 p-1 ${
                    index === notifications.length - 1
                      ? "border-b-0"
                      : "border-b"
                  } ${!notification.isSeen && "bg-sky-100"}`}
                >
                  <div className={`text-sm font-semibold `}>
                    {notification.title}
                  </div>
                  <div className="w-full text-xs text-gray-500 flex justify-between items-center">
                    <div className="text-md">{notification.description}</div>
                    <div>
                      {new Date(notification.createdAt).toLocaleString()}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {notifications.length > 0 ? (
              <div className="flex">
                <MyButton
                  variant="primary"
                  className="bg-primary text-white w-full mt-2 p-0"
                  bypassRoleCheck
                  onClick={markAllAsRead}
                  loading={loading}
                >
                  Mark All As Read
                </MyButton>
              </div>
            ) : (
              <div className="flex justify-center items-center w-full">
                <h1
                  variant="primary"
                  className="text-center text-xl font-semibold"
                  bypassRoleCheck
                >
                  No notifications
                </h1>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

const NavBar = () => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const location = useLocation();
  const { auth } = useSelector((state) => state);
  const { isAuth, user } = auth;
  const navigate = useNavigate();
  const restaurant = user.restaurant;
  const fetchNotifications = async () => {
    if (isAuth && restaurant?._id) {
      // const query = `?restaurant=${restaurant?._id}`;
      const query = ``;
      try {
        const res = await getAllNotifications(query);
        setNotifications(res);
      } catch (err) {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      fetchNotifications();
    }, 3000);

    // Clear the interval when the component is unmounted or the dependencies change
    return () => clearInterval(interval);
  }, []);
  return (
    <>
      <ConfirmationModal
        isOpen={isOpen}
        message={"Are you sure you want logout ?"}
        onClose={() => setIsOpen(false)}
        onConfirm={() => dispatch(logout())}
      />
      <Flex
        as="nav"
        className="bg-[#ffb04f] z-40"
        align="center"
        hidden={
          location.pathname === "/signup" || location.pathname === "/login"
        }
        // justify={{ base: "space-between", md: "flex-end" }}
        w="full"
        px="4"
        boxShadow="sm"
        height={"8vh"}
        position={"sticky"}
        top={0}
        justifyContent={"space-between"}
      >
        <div className="flex items-center justify-center">
          <IconButton
            aria-label="Menu"
            display={{ base: "inline-flex", md: "none" }}
            onClick={() => dispatch(handleNavBar())}
            icon={<FiMenu />}
            size="md"
          />
          <span className="lg:text-2xl text-xl font-semibold ml-4">
            Welcome, {auth?.user?.name}
          </span>
        </div>
        <Flex align="center" className="justify-end gap-4">
          <CustomPopup notifications={notifications} />
          <Menu>
            <MenuButton
              as={Button}
              rounded={"full"}
              variant={"link"}
              cursor={"pointer"}
              minW={0}
            >
              <Avatar size={"sm"} src={user?.avatar} name={user?.name} />
            </MenuButton>
            <MenuList className="flex flex-col gap-2">
              <MenuItem onClick={() => navigate("/profile")}>
                <Flex className="justify-center items-center gap-3">
                  <Icon
                    as={FaUser}
                    ml="4"
                    cursor="pointer"
                    className="text-xl text-black"
                  />
                  <p>Profile</p>
                </Flex>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setIsOpen(true);
                }}
              >
                <Flex className="justify-center items-center gap-3">
                  <Icon
                    as={FiLogOut}
                    ml="4"
                    cursor="pointer"
                    className="text-xl text-black"
                  />
                  <p>Logout</p>
                </Flex>
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </Flex>
    </>
  );
};

export default NavBar;
