import http from "./http";

const userApi = "/auth";
const otpApi = "/otp";

export const register = (data) => {
  return http.post(`${userApi}/register`, data);
};

export const login = (data) => {
  return http.post(`${userApi}/login`, data);
};
export const searchUser = (query) => {
  return http.get(`${userApi}/search${query}`);
};

export const getUser = () => {
  return http.get(`/user/get`);
};

export const logout = () => {
  return http.post(`${userApi}/logout`);
};

export const getAllUsers = () => {
  return http.get(`${userApi}/get`);
};

export const updateUser = (userId, data) => {
  return http.put(`${userApi}/update/${userId}`, data);
};

export const deleteUser = (userId) => {
  return http.delete(`${userApi}/delete/${userId}`);
};

export const sendOtp = (data) => {
  return http.post(`${otpApi}/sendOtp`, data);
};

export const verifyOtp = (data) => {
  return http.post(`${otpApi}/verifyOtp`, data);
};
export const forgotPasswordSendOtp = (data) => {
  return http.post(`${otpApi}/forgotPasswordSendOtp`, data);
};

export const updatePassword = (data) => {
  return http.put(`${userApi}/update/password`, data);
};
