import React from "react";
import { Box, Button, Image, Switch, Text } from "@chakra-ui/react";
import { FaEye, FaEdit } from "react-icons/fa";

const RestaurantCard = ({
  restaurant,
  toggleDetails,
  toggleOwnerDetails,
  editRestaurant,
  handleActiveChange,
  handleVerifiedChange,
}) => {
  return (
    <Box
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      boxShadow="md"
      p="2"
    >
      <Box>
        <Image
          src={restaurant.logo}
          alt="Logo"
          className="w-36 object-cover aspect-auto "
        />
      </Box>
      <Box>
        <Text fontWeight="bold" fontSize="lg" mt="2">
          {restaurant.name}
        </Text>
        <Text>Email: {restaurant.email}</Text>
        <Text>Phone: {restaurant.phone}</Text>
        {/* <Text>
          Active:{" "}
          <Switch
            isChecked={restaurant.isActive}
            colorScheme="teal"
            size="md"
            onChange={(e) =>
              handleActiveChange(restaurant, "isActive", e.target.checked)
            }
          />
        </Text> */}
        <div className="flex justify-start items-center gap-2 py-2">
          <Text>
            Verified:{" "}
            <Switch
              isChecked={restaurant.isVerified}
              colorScheme="teal"
              size="md"
              onChange={(e) =>
                handleVerifiedChange(restaurant._id, e.target.checked)
              }
            />
          </Text>
          <Text>
            Free Trial:{" "}
            <Switch
              isChecked={restaurant.isFreeTrial}
              colorScheme="teal"
              size="md"
              onChange={(e) =>
                handleActiveChange(restaurant, "isFreeTrial", e.target.checked)
              }
            />
          </Text>
        </div>
      </Box>
      <div className="mt-2 w-full flex justify-between">
        <Button
          colorScheme="blue"
          variant="outline"
          size="sm"
          onClick={() => toggleOwnerDetails(restaurant?.owner)}
        >
          <FaEye /> View Owner
        </Button>
        <Button
          colorScheme="green"
          variant="outline"
          size="sm"
          onClick={() => editRestaurant(restaurant)}
        >
          <FaEdit /> Edit
        </Button>
        <Button
          colorScheme="teal"
          variant="outline"
          size="sm"
          onClick={() => toggleDetails(restaurant)}
        >
          Details
        </Button>
      </div>
    </Box>
  );
};

export default RestaurantCard;
