import React, { useState } from "react";
import { FaEdit, FaTrash } from "react-icons/fa";
import {
  capitalizeFirstLetter,
  getErrorMessage,
} from "../../../utils/functions";
import { useNavigate } from "react-router-dom";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  IconButton,
  Image,
  Text,
  TableContainer,
  Switch,
} from "@chakra-ui/react";
import InputField from "../../../UI/InputField";
import { Label } from "mdi-material-ui";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { updateEmployee } from "../../../services/employeeApi";
import imageConstant from "../../../constants/imageConstant";

const EmployeeTable = ({
  employees,
  showConfirmationModal,
  setShowEmployee,
  setEmployees,
}) => {
  const [showAllDetails, setShowAllDetails] = useState(false);
  const navigate = useNavigate();

  const handleShowDetails = () => {
    setShowAllDetails((prev) => !prev);
  };

  const { user } = useSelector((state) => state.auth);

  const excludedKeys = [
    "__v",
    "createdAt",
    "updatedAt",
    "_id",
    "restaurant",
    "description",
    "price",
    "password",
    "provider",
    "isPresent",
    "department",
    // "avatar",
    "age",
  ];

  const employeeFields = Object.keys(employees[0] || {}).filter(
    (key) => !excludedKeys.includes(key)
  );

  const handleEdit = (employee) => {
    if (employee._id === user._id)
      return toast.error(
        "You can't edit from here. Go to your profile and update your details."
      );
    navigate(`/restaurant-admins`, {
      state: { employee, isEdit: true },
      replace: true,
    });
    setShowEmployee(true);
  };

  const update = async (e, employee, data) => {
    e.preventDefault();
    try {
      await updateEmployee(data, `${employee._id}`);
      setEmployees((prevEmployees) =>
        prevEmployees.map((emp) => {
          if (emp._id === employee._id) {
            return { ...emp, ...data };
          }
          return emp;
        })
      );
    } catch (error) {
      if (Array.isArray(error)) {
        // If error is an array, toast each error message
        error.forEach((err) => {
          toast.error(getErrorMessage(err));
        });
      } else {
        // If not an array, toast the single error message
        toast.error(getErrorMessage(error));
      }
    }
  };

  return (
    <TableContainer overflowX="auto">
      <Table variant="striped" colorScheme="gray">
        <Thead>
          <Tr>
            {employeeFields.map((field) => {
              if (field === "avatar")
                return <Th key={field}>{capitalizeFirstLetter("Photo")}</Th>;
              return <Th key={field}>{capitalizeFirstLetter(field)}</Th>;
            })}
            {/* <Th>Attendance</Th> */}
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {employees.map((employee, index) => {
            return (
              <Tr key={index}>
                {employeeFields.map((field) => (
                  <Td key={field}>
                    {field === "avatar" ? (
                      <Image
                        src={employee[field] || imageConstant.waiter}
                        alt="Employee Photo"
                        boxSize="50px"
                        objectFit="cover"
                        borderRadius="md"
                      />
                    ) : showAllDetails ? (
                      <Text>{employee[field]}</Text>
                    ) : (
                      <Text isTruncated>{employee[field]}</Text>
                    )}
                  </Td>
                ))}
                {/* <Td>
                  <div className="flex justify-center items-center">
                    <Switch
                      isChecked={employee.isPresent}
                      onChange={(e) => {
                        update(e, employee, {
                          isPresent: !employee.isPresent,
                        });
                      }}
                      colorScheme="teal"
                      size="md"
                    />
                    <p className="ml-2">
                      {employee.isPresent ? "Present" : "Absent"}
                    </p>
                  </div>
                </Td> */}
                <Td>
                  <IconButton
                    colorScheme="blue"
                    variant="outline"
                    size="sm"
                    aria-label="Edit"
                    icon={<FaEdit />}
                    className="m-2"
                    onClick={() => handleEdit(employee)}
                  />
                  {/* <IconButton
                    colorScheme="red"
                    variant="outline"
                    size="sm"
                    aria-label="Delete"
                    icon={<FaTrash />}
                    onClick={() => showConfirmationModal(employee)}
                  /> */}
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default EmployeeTable;
