import http from "./http";

const restaurantApi = "/restaurant";

export const addRestaurant = (data, query = "") => {
  return http.post(`${restaurantApi}/create${query}`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const getAllRestaurants = async () => {
  const res = await http.get(`${restaurantApi}/all`);
  return res;
};

export const getRestaurantAnalytics = async () => {
  const res = await http.get(`${restaurantApi}/admin/analytics`);
  return res;
};

export const deleteRestaurant = async (params) => {
  return http.delete(`${restaurantApi}/delete/${params}`);
};

export const updateRestaurant = async (data, params) => {
  return http.put(`${restaurantApi}/update/${params}`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
