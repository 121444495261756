import {
  Box,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { State } from "country-state-city";
import moment from "moment";
import React from "react";
import { capitalizeFirstLetter } from "../utils/functions";
import { useSelector } from "react-redux";

const excludeFieldsInModal = [
  "_id",
  "__v",
  "updatedAt",
  "owner",
  "password",
  "provider",
  "restaurant",
  "role",
  "subscriptionDetails",
  "isActive",
  "isPresent",
];

const TabularDialog = ({ isOpen, onClose, title, details }) => {
  const mobileView = useSelector((state) => state.auth.mobileView);
  return (
    <Modal isOpen={isOpen} onClose={onClose} size={mobileView ? "sm" : "xl"}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <table className="table-auto w-full">
            <tbody>
              {/* Iterate over details */}
              {Object.entries(details || {}).map(
                ([key, value]) =>
                  !excludeFieldsInModal.includes(key) && (
                    <tr key={key}>
                      <td className="py-2 px-4 border border-gray-300">
                        <strong className="lg:text-md text-xs">
                          {capitalizeFirstLetter(key)}
                        </strong>
                      </td>
                      <td className="py-2 px-4 border border-gray-300 text-xs lg:text-md">
                        {value != null && value !== "" ? (
                          key === "logo" || key === "avatar" ? (
                            <Box maxW="100px">
                              <Image src={value} alt="Logo" />
                            </Box>
                          ) : key === "address" ? (
                            <>
                              {`${value.street}, ${value.city} (${
                                value.postalCode
                              }), ${
                                State.getStateByCodeAndCountry(
                                  value.state,
                                  "IN"
                                )?.name
                              }`}
                            </>
                          ) : key === "isVerified" ||
                            key === "isFreeTrial" ||
                            key === "isSubscriptionActive" ? (
                            value ? (
                              "True"
                            ) : (
                              "False"
                            )
                          ) : key === "createdAt" ? (
                            moment(value).format("MMMM Do YYYY, h:mm:ss a")
                          ) : value == "undefined" ||
                            value == "null" ||
                            value == "" ? (
                            ""
                          ) : (
                            capitalizeFirstLetter(value)
                          )
                        ) : (
                          "-"
                        )}
                      </td>
                    </tr>
                  )
              )}
              {details?.subscriptionDetails && (
                <tr>
                  <td className="py-2 px-4 border border-gray-300">
                    <strong className="lg:text-md text-xs">
                      Subscription Details
                    </strong>
                  </td>
                  <td className="py-2 px-4 border border-gray-300 text-xs lg:text-md">
                    <p>Plan: {details.subscriptionDetails?.months} months</p>
                    <p>
                      Plan end date:{" "}
                      {moment(details.subscriptionDetails?.planEndDate).format(
                        "Do MMM YYYY"
                      )}
                    </p>
                    <p>
                      Amount paid: {details.subscriptionDetails?.amountPaid}
                    </p>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default TabularDialog;
