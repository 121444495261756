import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import Loader from "../../UI/Loader";
import RestaurantList from "./components/RestaurantList";
import {
  appendIfDefined,
  createImageFileFromUrl,
  createImagePreview,
  createImagePreviewFromUrl,
  getErrorMessage,
  validateFields,
} from "../../utils/functions";
import { useLocation, useNavigate } from "react-router-dom";
import RestaurantForm from "./components/RestaurantForm";
import { addRestaurant, updateRestaurant } from "../../services/restaurantApi";
import RestaurantActions from "./components/RestaurantActions";
import { ROLES } from "../../constants/roles";
import { City } from "country-state-city";
import { SetCenterRight } from "mdi-material-ui";

const AllRestaurants = ({}) => {
  const [loading, setLoading] = useState(false);
  const [restaurant, setRestaurant] = useState({});
  const { auth } = useSelector((state) => state);
  const [images, setImages] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [totalRestaurants, setTotalRestaurants] = useState(0);
  const [showRestaurant, setShowRestaurant] = useState(true);
  const [isRestaurantEdit, setIsRestaurantEdit] = useState(false);
  const [cities, setCities] = useState([]);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.state) {
      const { restaurant, isEdit } = location.state;
      setIsRestaurantEdit(isEdit);
      setRestaurant(restaurant);
      const image = restaurant.logo;
      setImages([image]);
      createImageFileFromUrl(image, restaurant.name).then((imageFile) => {
        setImages([imageFile]);
        createImagePreviewFromUrl(restaurant.logo).then((previewUrl) => {
          setImagePreviews([previewUrl]);
        });
      });
    }
  }, [location.state]);
  const handleImageUpload = (e) => {
    // Commented out logic for multiple images
    // const newImages = [...images, ...e.target.files];
    // setImages(newImages);

    // Allowing only one image
    const newImages = [e.target.files[0]];
    setImages(newImages);

    const previewArray = newImages.map((image) => {
      return createImagePreview(image);
    });
    setImagePreviews(previewArray);
  };

  const handleAddRestaurant = async (e) => {
    try {
      e.preventDefault();
      if (images?.length === 0) {
        return toast.error("Image is required");
      }

      const missingField = validateFields(
        ["name", "email", "phone"],
        restaurant
      );
      const missingAddressField = validateFields(
        ["city", "state", "postalCode", "street"],
        restaurant.address
      );
      if (missingAddressField) {
        return toast.error(missingAddressField);
      }
      if (missingField) {
        return toast.error(missingField);
      }
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(restaurant.email))
        return toast.error("Please enter valid email address");
      //if phone contain + then toast
      if (restaurant.phone.includes("+"))
        return toast.error("Please enter phone without country code");
      //check whether phone number is valid or not
      const phoneRegex = /^[0-9]{10}$/;
      if (!phoneRegex.test(restaurant.phone))
        return toast.error("Please enter valid phone number");

      setLoading(true);

      const formData = new FormData();

      // Append text fields to FormData
      appendIfDefined(formData, "name", restaurant.name);
      appendIfDefined(formData, "description", restaurant.description);
      const address = {
        city: restaurant?.address.city || restaurant.city,
        street: restaurant?.address.street || restaurant.street,
        state: restaurant?.address.state || restaurant.state,
        postalCode: restaurant?.address.postalCode || restaurant.postalCode,
      };

      for (const key in address) {
        if (address.hasOwnProperty(key)) {
          appendIfDefined(formData, `address[${key}]`, address[key]);
        }
      }
      appendIfDefined(formData, "phone", restaurant.phone);
      appendIfDefined(formData, "email", restaurant.email);
      if (
        restaurant.owner &&
        typeof restaurant.owner === "object" &&
        restaurant.owner._id
      ) {
        appendIfDefined(formData, "owner", restaurant.owner._id);
      } else if (restaurant.owner) {
        appendIfDefined(formData, "owner", restaurant.owner);
      }
      // Append image files to FormData
      images?.forEach((image, index) => {
        formData.append(`image`, image);
      });

      if (isRestaurantEdit) {
        await updateRestaurant(formData, `${restaurant._id}`);
        toast.success("Restaurant updated successfully!");
      } else {
        await addRestaurant(formData);
        toast.success("Restaurant added successfully!");
      }

      //   setShowVerificationDetails(false);
      setLoading(false);
      setImages([]);
      setImagePreviews([]);
      // setShowRestaurant(false);
      navigate("/restaurants");
      return;
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (auth?.restaurant) setRestaurant(auth.restaurant);
  }, []);

  const removeImage = (index) => {
    const newImages = [...images];
    const newPreviews = [...imagePreviews];

    newImages.splice(index, 1);
    newPreviews.splice(index, 1);

    setImages(newImages);
    setImagePreviews(newPreviews);
  };

  const handleInputChange = (field, value) => {
    if (field === "postalCode" || field === "street") {
      setRestaurant((prevItem) => ({
        ...prevItem,
        address: {
          ...prevItem.address,
          [field]: value,
        },
      }));
    }
    setRestaurant((prevItem) => ({
      ...prevItem,
      [field]: value,
    }));
  };
  const handleSelectChange = (field, selectedValue) => {
    if (field === "state") {
      const selectedState = selectedValue.value;
      const cities = City.getCitiesOfState("IN", selectedState);
      setCities(cities);
      const firstCity = cities.length > 0 ? cities[0].name : "";
      setRestaurant((prevItem) => ({
        ...prevItem,
        address: {
          ...prevItem.address,
          [field]: selectedValue.value,
          city: firstCity,
        },
      }));
    } else if (field === "city") {
      setRestaurant((prevItem) => ({
        ...prevItem,
        address: {
          ...prevItem.address,
          [field]: selectedValue.value,
        },
      }));
    } else {
      setRestaurant((prevItem) => ({
        ...prevItem,
        [field]: selectedValue.value,
      }));
    }
  };

  const handleSetShowRestaurant = () => {
    setShowRestaurant((prev) => !prev);
    navigate("/restaurant-admins", {
      state: null,
      replace: true,
    });
    setRestaurant({});
    setImagePreviews([]);
    setIsRestaurantEdit(false);
    setImages([]);
  };
  if (loading) return <Loader />;
  return (
    <div className="bg-white">
      <RestaurantActions
        handleSetShowRestaurant={handleSetShowRestaurant}
        // showRestaurant={showRestaurant}
        // totalRestaurants={totalRestaurants}
        key={0}
      />
      {showRestaurant ? (
        <div className="flex flex-col bg-white">
          <RestaurantForm
            loading={loading}
            restaurant={restaurant}
            images={images}
            imagePreviews={imagePreviews}
            handleImageUpload={handleImageUpload}
            removeImage={removeImage}
            handleInputChange={handleInputChange}
            setShowRestaurant={setShowRestaurant}
            handleSelectChange={handleSelectChange}
            addRestaurant={handleAddRestaurant}
            isRestaurantEdit={isRestaurantEdit}
            cities={cities}
            setRestaurant={setRestaurant}
          />
        </div>
      ) : (
        <RestaurantList
          totalRestaurants={totalRestaurants}
          setTotalRestaurants={setTotalRestaurants}
          showRestaurant={showRestaurant}
          setShowRestaurant={setShowRestaurant}
        />
        // <>Restaurant List</>
      )}
    </div>
  );
};

export default AllRestaurants;
