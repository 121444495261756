import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import Loader from "../../UI/Loader";
import EmployeeList from "./components/EmployeeList";
import {
  appendIfDefined,
  createImageFileFromUrl,
  createImagePreview,
  createImagePreviewFromUrl,
  getErrorMessage,
  validateFields,
} from "../../utils/functions";
import { useLocation, useNavigate } from "react-router-dom";
import EmployeeForm from "./components/EmployeeForm";
import { addEmployee, updateEmployee } from "../../services/employeeApi";
import EmployeeActions from "./components/EmployeeActions";
import { ROLES } from "../../constants/roles";

const Employees = ({}) => {
  const [loading, setLoading] = useState(false);
  const [employee, setEmployee] = useState({});
  const { auth } = useSelector((state) => state);
  const [images, setImages] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [totalEmployees, setTotalEmployees] = useState(0);
  const [showEmployee, setShowEmployee] = useState(true);
  const [isEmployeeEdit, setIsEmployeeEdit] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.state) {
      const { employee, isEdit } = location.state;
      setIsEmployeeEdit(isEdit);
      setEmployee(employee);
      const image = employee.avatar;

      setImages([image]);
      if (images.length > 0) {
        createImageFileFromUrl(image, employee.name).then((imageFile) => {
          setImages([imageFile]);
        });
        createImagePreviewFromUrl(employee.avatar).then((previewUrl) => {
          setImagePreviews([previewUrl]);
        });
      }
    }
  }, [location.state]);
  const handleImageUpload = (e) => {
    // Commented out logic for multiple images
    // const newImages = [...images, ...e.target.files];
    // setImages(newImages);

    // Allowing only one image
    const newImages = [e.target.files[0]];
    setImages(newImages);

    const previewArray = newImages.map((image) => {
      return createImagePreview(image);
    });
    setImagePreviews(previewArray);
  };
  const handleAddEmployee = async (e) => {
    try {
      e.preventDefault();

      // Validate form fields
      const missingField = validateFormFields();
      if (missingField) {
        return toast.error(missingField);
      }
      if (employee.password !== employee.confirmPassword) {
        return toast.error("Password not matching");
      }
      if (employee?.mobileNumber.length !== 10) {
        return toast.error("Please enter correct phone number");
      }
      if (employee.age < 18) {
        return toast.error("Employee should be atleast 18");
      }
      setLoading(true);

      // Prepare FormData
      const formData = prepareFormData();

      // Submit form data
      if (isEmployeeEdit) {
        await updateEmployeeData(formData);
        toast.success("Employee updated successfully!");
      } else {
        await addNewEmployee(formData);
        toast.success("Employee added successfully!");
      }

      // Reset form and state
      resetFormState();

      navigate('/create-restaurant')
    } catch (error) {
      handleFormSubmissionError(error);
    } finally {
      setLoading(false);
    }
  };

  const validateFormFields = () => {
    const requiredFields = ["name", "age", "email", "mobileNumber"];
    if (!isEmployeeEdit) {
      requiredFields.push("password");
    }

    return validateFields(requiredFields, employee);
  };

  const prepareFormData = () => {
    const formData = new FormData();
    appendIfDefined(formData, "name", employee.name);
    appendIfDefined(formData, "age", employee.age);
    appendIfDefined(formData, "department", "Admin");
    appendIfDefined(formData, "password", employee.password);
    appendIfDefined(formData, "email", employee.email);
    appendIfDefined(formData, "provider", ROLES.SUPER_ADMIN);
    appendIfDefined(formData, "role", ROLES.RESTAURANT_ADMIN);
    appendIfDefined(formData, "mobileNumber", employee.mobileNumber);

    // Append image files to FormData
    images.forEach((image, index) => {
      formData.append(`image`, image);
    });

    return formData;
  };

  const updateEmployeeData = async (formData) => {
    await updateEmployee(formData, `${employee._id}`);
  };

  const addNewEmployee = async (formData) => {
    await addEmployee(formData);
  };

  const resetFormState = () => {
    setEmployee({});
    setShowEmployee(false);
    setImages([]);
    setImagePreviews([]);
    setIsEmployeeEdit(false);
  };

  const handleFormSubmissionError = (error) => {
    if (Array.isArray(error)) {
      error.forEach((err) => {
        toast.error(getErrorMessage(err));
      });
    } else {
      toast.error(getErrorMessage(error));
    }
  };

  useEffect(() => {
    if (auth?.employee) setEmployee(auth.employee);
  }, []);

  const removeImage = (index) => {
    const newImages = [...images];
    const newPreviews = [...imagePreviews];

    newImages.splice(index, 1);
    newPreviews.splice(index, 1);

    setImages(newImages);
    setImagePreviews(newPreviews);
  };

  const handleInputChange = (field, value) => {
    setEmployee((prevItem) => ({
      ...prevItem,
      [field]: value,
    }));
  };
  const handleSelectChange = (field, selectedValue) => {
    setEmployee((prevItem) => ({
      ...prevItem,
      [field]: selectedValue.value,
    }));
  };
  const handleSetShowEmployee = () => {
    setShowEmployee((prev) => !prev);
    navigate("/restaurant-admins", {
      state: null,
      replace: true,
    });
    setEmployee({});
    setImagePreviews([]);
    setIsEmployeeEdit(false);
    setImages([]);
  };
  if (loading) return <Loader />;

  return (
    <div className="bg-white">
      {/* <EmployeeActions
        handleSetShowEmployee={handleSetShowEmployee}
        showEmployee={showEmployee}
        totalEmployees={totalEmployees}
        key={0}
      /> */}
      {showEmployee ? (
        <div className="flex flex-col bg-white">
          <EmployeeForm
            loading={loading}
            employee={employee}
            images={images}
            imagePreviews={imagePreviews}
            handleImageUpload={handleImageUpload}
            removeImage={removeImage}
            handleInputChange={handleInputChange}
            handleSelectChange={handleSelectChange}
            addEmployee={handleAddEmployee}
            isEmployeeEdit={isEmployeeEdit}
          />
        </div>
      ) : (
        <EmployeeList
          totalEmployees={totalEmployees}
          setTotalEmployees={setTotalEmployees}
          showEmployee={showEmployee}
          setShowEmployee={setShowEmployee}
        />
      )}
    </div>
  );
};

export default Employees;
