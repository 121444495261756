import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { getRestaurantAnalytics } from "../../services/restaurantApi";
import ReactApexChart from "react-apexcharts";
import { BsGraphUp } from "react-icons/bs";
import { MdMoney } from "react-icons/md";
import { TfiMoney } from "react-icons/tfi";
import Loader from "../../UI/Loader";

const months = {
  1: "January",
  2: "February",
  3: "March",
  4: "April",
  5: "May",
  6: "June",
  7: "July",
  8: "August",
  9: "September",
  10: "October",
  11: "November",
  12: "December",
};

const Dashboard = () => {
  const [loading, setLoading] = useState(false);
  const [analytics, setAnalytics] = useState({});

  useEffect(() => {
    setLoading(true);
    getRestaurantAnalytics()
      .then((res) => {
        setAnalytics(res);
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoading(false));
  }, []);

  const monthlyEarningsEachMonthData = {
    series: [
      {
        name: "Monthly Payments",
        data:
          analytics?.monthlyPaymentsEachMonth?.map((item) => item.amount) || [],
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      xaxis: {
        title: { text: "Month" },
        categories: Object.keys(months).map((key) => months[key]),
      },
      yaxis: {
        title: { text: "Amount" },
      },
    },
  };

  const dailyEarningsEachMonthData = {
    series: [
      {
        name: "Daily Payments",
        data: analytics?.dailyEarning?.map((item) => item.amount) || [],
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "bar",
        zoom: {
          enabled: false,
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          dataLabels: {
            position: "top",
          },
        },
      },
      dataLabels: {
        enabled: true,
      },
      stroke: {
        curve: "smooth",
      },
      xaxis: {
        title: { text: "Day" },
        categories: analytics?.dailyEarning?.map((item) => item.day) || [],
      },
      yaxis: {
        title: { text: "Amount" },
      },
    },
  };

  if (loading) return <Loader />;

  return (
    <div className="p-4">
      <div className="flex justify-center items-center gap-6 mt-7 lg:flex-1 w-full lg:flex-row flex-col">
        <div className=" p-4 rounded-xl flex-1 w-full bg-green-100 border border-green-400">
          <h3 className="font-medium text-left text-md">
            Ongoing Subscriptions
          </h3>
          <div className="flex justify-between items-center gap-3 mt-5">
            <BsGraphUp size={30} className="text-green-500 mt-1 font-bold" />
            <p className=" text-4xl">{analytics.subscribedRestaurants || 0}</p>
          </div>
        </div>
        <div className=" p-4 rounded-xl flex-1 w-full bg-gray-100 border border-gray-400">
          <h3 className="font-medium text-left text-md">
            Weekly Registrations
          </h3>
          <div className="flex justify-between items-center gap-3 mt-5">
            <BsGraphUp size={30} className="text-green-500 mt-1 font-bold" />
            <p className=" text-4xl">{analytics.weeklyRestaurants || 0}</p>
          </div>
        </div>
        <div className=" p-4 rounded-xl flex-1 w-full bg-sky-100 border border-sky-400">
          <h3 className="font-medium text-left text-md">
            Monthly Registrations
          </h3>
          <div className="flex justify-between items-center gap-3 mt-5">
            <MdMoney size={40} className="text-blue-500 mt-1" />
            <p className=" text-4xl">{analytics.monthlyRestaurants || 0}</p>
          </div>
        </div>
        <div className=" p-4 rounded-xl flex-1 w-full bg-orange-100 border border-orange-400">
          <h3 className="font-medium text-left text-md">
            Yearly Registrations
          </h3>
          <div className="flex justify-between items-center gap-3 mt-5">
            <TfiMoney size={30} className="text-orange-500 mt-1" />
            <p className=" text-4xl">{analytics.yearlyRestaurants || 0}</p>
          </div>
        </div>
      </div>
      <div className="flex justify-center items-center gap-6 mt-7 lg:flex-1 w-full lg:flex-row flex-col">
        <div className=" p-4 rounded-xl flex-1 w-full bg-green-100 border border-green-400">
          <h3 className="font-medium text-left text-md">
            Weekly Payments <small>(Current week)</small>
          </h3>

          <div className="flex justify-between items-center gap-3 mt-5">
            <BsGraphUp size={30} className="text-green-500 mt-1 font-bold" />
            <p className=" text-4xl">₹{analytics.weeklyPayments || 0}</p>
          </div>
        </div>
        <div className=" p-4 rounded-xl flex-1 w-full bg-sky-100 border border-sky-400">
          <h3 className="font-medium text-left text-md">
            Monthly Payments <small>(Current month)</small>
          </h3>
          <div className="flex justify-between items-center gap-3 mt-5">
            <MdMoney size={40} className="text-blue-500 mt-1" />
            <p className=" text-4xl">₹{analytics.monthlyPayments || 0}</p>
          </div>
        </div>
        <div className=" p-4 rounded-xl flex-1 w-full bg-orange-100 border border-orange-400">
          <h3 className="font-medium text-left text-md">Yearly Payments</h3>
          <div className="flex justify-between items-center gap-3 mt-5">
            <TfiMoney size={30} className="text-orange-500 mt-1" />
            <p className=" text-4xl">₹{analytics.yearlyPayments || 0}</p>
          </div>
        </div>
        <div className=" p-4 rounded-xl flex-1 w-full bg-yellow-100 border border-yellow-400">
          <h3 className="font-medium text-left text-md">Ongoing Free Trials</h3>
          <div className="flex justify-between items-center gap-3 mt-5">
            <TfiMoney size={30} className="text-yellow-500 mt-1" />
            <p className=" text-4xl">{analytics.freeTrialRestaurants || 0}</p>
          </div>
        </div>
      </div>
      <div
        id="chart"
        className="flex justify-center items-stretch gap-6 mt-10 lg:flex-row flex-col flex-1"
      >
        <div className="bg-white p-4 rounded-xl flex flex-col flex-1 border border-gray-300">
          <h3>Monthly Overview</h3>
          <ReactApexChart
            options={monthlyEarningsEachMonthData?.options}
            series={monthlyEarningsEachMonthData?.series}
            type="line"
          />
        </div>
        <div className="bg-white p-4 rounded-xl flex flex-col flex-1 border border-gray-300">
          <h3>Weekly Overview</h3>
          <ReactApexChart
            options={dailyEarningsEachMonthData?.options}
            series={dailyEarningsEachMonthData?.series}
            type="bar"
          />
        </div>
        <div id="html-dist"></div>
      </div>
    </div>
  );
};

export default Dashboard;
