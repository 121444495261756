import logo from "../assets/images/logo.png";
import classPic from "../assets/images/class.svg";
import interview from "../assets/images/interview.svg";
import signupBg from "../assets/images/signup-bg.png";
import qa from "../assets/images/qa.svg";
import live from "../assets/images/live.svg";
import premium from "../assets/images/premium.svg";
import teaching from "../assets/images/teaching.svg";
import look from "../assets/images/look.png";
import onlineClass from "../assets/images/online-class.png";
import classIcon from "../assets/images/classIcon.png";
import premiumIcon from "../assets/images/premiumIcon.jpeg";
import loginSide from "../assets/images/login_side.jpg";
import cafeDoodle from "../assets/images/cafe-doodle.jpg";
import waiter from "../assets/images/waiter.avif";
import signup from "../assets/images/signup.jpg";
export default {
  logo,
  classPic,
  interview,
  signupBg,
  qa,
  live,
  premium,
  teaching,
  look,
  onlineClass,
  classIcon,
  premiumIcon,
  loginSide,
  cafeDoodle,
  waiter,
  signup,
  allFoods:
    "https://www.foodlocale.in/wp-content/uploads/2022/11/food-1024x683.jpg",
};
