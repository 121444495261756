import Login from "../components/Login/Login";
import SignUp from "../components/SignUp/SignUp";
// import Menu from "../Pages/Menu/Menus";
// import Employees from "../Pages/Employee/Employees";
// import Tables from "../Pages/Tables/Tables";
import Restaurants from "../Pages/Restaurant/Restaurants";
import { State } from "country-state-city";
import Employees from "../Pages/Employee/Employees";
import Dashboard from "../Pages/Dashboard/Dashboard";
import Menus from "../Pages/Menu/Menus";
import Profile from "../Pages/Profile/Profile";
import AllRestaurants from "../Pages/Restaurant/AllRestaurants";
import Settings from "../Pages/Settings/Settings";
import { ROLES } from "./roles";

export const Pages = [
  {
    path: "/login",
    component: Login,
    isAuth: false,
    isAuthenticationRoute: true,
  },
  {
    path: "/create-restaurant",
    component: AllRestaurants,
    isAuth: true,
    roles: [ROLES.SUPER_ADMIN],
  },
  // {
  //   path: "/menus",
  //   component: Menu,
  //   isAuth: true,
  //   roles: [ROLES.SUPER_ADMIN],
  // },
  // {
  //   path: "/restaurant-admins",
  //   component: Employees,
  //   isAuth: true,
  //   roles: [ROLES.SUPER_ADMIN],
  // },
  // {
  //   path: "/tables",
  //   component: Tables,
  //   isAuth: true,
  //   roles: [ROLES.SUPER_ADMIN],
  // },
  {
    path: "/restaurants",
    component: Restaurants,
    isAuth: true,
    roles: [ROLES.SUPER_ADMIN],
  },
  {
    path: "/restaurant-admins",
    component: Employees,
    isAuth: true,
    roles: [ROLES.SUPER_ADMIN],
  },
  {
    path: "/dashboard",
    component: Dashboard,
    isAuth: true,
    roles: [ROLES.SUPER_ADMIN],
  },
  {
    path: "/signup",
    component: SignUp,
    isAuth: false,
    isAuthenticationRoute: true,
  },
  {
    path: "/profile",
    component: Profile,
    isAuth: true,
    roles: [ROLES.SUPER_ADMIN],
  },
  {
    path: "/menus",
    component: Menus,
    isAuth: true,
    roles: [ROLES.SUPER_ADMIN],
  },
  {
    path: "/settings",
    component: Settings,
    isAuth: true,
    roles: [ROLES.SUPER_ADMIN],
  },
  {
    path: "/",
    component: Login,
    isAuth: false,
    isAuthenticationRoute: true,
    // roles: [ROLES.SUPER_ADMIN],
  },

  // {
  //   path: "*",
  //   component: NotFound,
  //   isAuth: false,
  // },
];

export const menuFields = [
  {
    label: "Item Name",
    type: "text",
    name: "name",
    isRequired: true,
  },
  // {
  //   label: "Quantity",
  //   type: "number",
  //   name: "quantity",
  //   isRequired: true,
  // },
  // {
  //   isRequired: true,
  //   label: "Quantity Unit",
  //   type: "select",
  //   name: "quantityUnit",
  // },
  // {
  //   label: "Price",
  //   type: "number",
  //   name: "price",
  //   isRequired: true,
  // },
  // {
  //   label: "KOT",
  //   name: "kot",
  //   type: "select",
  //   options: [
  //     {
  //       label: "KOT 1",
  //       value: "KOT 1",
  //     },
  //     {
  //       label: "KOT 2",
  //       value: "KOT 2",
  //     },
  //   ],
  // },
  {
    label: "Variant",
    name: "variant",
    type: "select",
    isRequired: true,
    options: [
      {
        label: "Veg",
        value: "Veg",
      },
      {
        label: "Non Veg",
        value: "Non veg",
      },
      {
        label: "Vegan",
        value: "Vegan",
      },
      {
        label: "Egg",
        value: "Egg",
      },
    ],
  },
  {
    isRequired: true,
    label: "Category",
    name: "category",
    type: "select",
    options: [
      {
        label: "Indian Cuisine",
        value: "Indian Cuisine",
      },
      {
        label: "Fast Food",
        value: "Fast Food",
      },
      {
        label: "South Indian Delights",
        value: "South Indian Delights",
      },
      {
        label: "Continental Cuisine",
        value: "Continental Cuisine",
      },
      {
        label: "Chinese Cuisine",
        value: "Chinese Cuisine",
      },
      {
        label: "Mediterranean Delicacies",
        value: "Mediterranean Delicacies",
      },
      {
        label: "Japanese Sushi and Sashimi",
        value: "Japanese Sushi and Sashimi",
      },
      {
        label: "Mexican Flavors",
        value: "Mexican Flavors",
      },
      {
        label: "Italian Pasta and Pizza",
        value: "Italian Pasta and Pizza",
      },
      {
        label: "Middle Eastern Cuisine",
        value: "Middle Eastern Cuisine",
      },
      {
        label: "American BBQ",
        value: "American BBQ",
      },
      {
        label: "Thai Street Food",
        value: "Thai Street Food",
      },
      {
        label: "Greek Souvlaki",
        value: "Greek Souvlaki",
      },
      {
        label: "Brazilian Steakhouse",
        value: "Brazilian Steakhouse",
      },
      {
        label: "Korean BBQ",
        value: "Korean BBQ",
      },
      {
        label: "Vietnamese Pho",
        value: "Vietnamese Pho",
      },
      {
        label: "African Safari",
        value: "African Safari",
      },
      {
        label: "Australian Bush Tucker",
        value: "Australian Bush Tucker",
      },
      {
        label: "Scandinavian Smorgasbord",
        value: "Scandinavian Smorgasbord",
      },
      {
        label: "Russian Borscht",
        value: "Russian Borscht",
      },
      {
        label: "Other",
        value: "Other",
      },
    ],
  },
  {
    isRequired: true,
    name: "description",
    label: "Description",
    type: "textarea",
  },
  {
    name: "isSpecial",
    label: "isSpecial",
    type: "checkbox",
  },
  {
    name: "isActive",
    label: "isActive",
    type: "checkbox",
  },
  {
    name: "isTopRated",
    label: "isTopRated",
    type: "checkbox",
  },
];

export const ERROR_MSG = "Something went wrong!";

export const employeeFields = [
  // {
  //   label: "Role",
  //   type: "select",
  //   name: "role",
  //   options: [
  //     {
  //       label: "Employee",
  //       value: ROLES.EMPLOYEE,
  //     },
  //     {
  //       label: "Restaurant Admin",
  //       value: ROLES.RESTAURANT_ADMIN,
  //     },
  //     {
  //       label: "Super Admin",
  //       value: ROLES.SUPER_ADMIN,
  //     },
  //   ],
  //   isRequired: true,
  // },
  {
    label: "Email",
    type: "email",
    name: "email",
    isRequired: true,
  },
  {
    label: "Mobile No",
    type: "text",
    name: "mobileNumber",
    isRequired: true,
  },
  {
    label: "Name",
    type: "text",
    name: "name",
    isRequired: true,
  },
  {
    label: "Age",
    type: "number",
    name: "age",
    isRequired: true,
  },

  // {
  //   label: "Department",
  //   type: "select",
  //   name: "department",
  //   options: [
  //     {
  //       label: "Chef",
  //       value: "Chef",
  //     },
  //     {
  //       label: "Waiter",
  //       value: "Waiter",
  //     },
  //     {
  //       label: "Bartender",
  //       value: "Bartender",
  //     },
  //     {
  //       label: "Manager",
  //       value: "Manager",
  //     },
  //     {
  //       label: "Cashier",
  //       value: "Cashier",
  //     },
  //     {
  //       label: "Host/Hostess",
  //       value: "Host/Hostess",
  //     },
  //     {
  //       label: "Sous Chef",
  //       value: "Sous Chef",
  //     },
  //     {
  //       label: "Busser",
  //       value: "Busser",
  //     },
  //     {
  //       label: "Dishwasher",
  //       value: "Dishwasher",
  //     },
  //     {
  //       label: "Server",
  //       value: "Server",
  //     },
  //   ],
  //   isRequired: true,
  // },
  {
    label: "Password",
    type: "password",
    name: "password",
    isRequired: true,
  },
  {
    label: "Confirm Password",
    type: "password",
    name: "confirmPassword",
    isRequired: true,
  },
];

export const restaurantFields = [
  {
    label: "Restaurant Name",
    type: "text",
    name: "name",
    isRequired: true,
  },
  {
    label: "Description",
    type: "textarea",
    name: "description",
    info: true,
    infoTitle: "Enter the description about your restaurant.",
  },
  {
    label: "Street",
    type: "text",
    name: "street",
    isRequired: true,
  },
  // {
  //   label: "City",
  //   type: "select",
  //   name: "city",
  //   options: City.getCitiesOfState("IN","TG").map((item) => {
  //     return {
  //       label: item.name,
  //       value: item.name,
  //     };
  //   }),
  // },
  {
    label: "State",
    type: "select",
    name: "state",
    options: State.getStatesOfCountry("IN").map((item) => {
      return {
        label: item.name,
        value: item.isoCode,
      };
    }),
    isRequired: true,
  },
  {
    label: "Postal Code",
    type: "text",
    name: "postalCode",
    isRequired: true,
  },
  {
    label: "Phone",
    type: "text",
    name: "phone",
    isRequired: true,
  },
  {
    label: "Email",
    type: "email",
    name: "email",
    isRequired: true,
  },
];
