import React, { useState, useEffect } from "react";
import CommonModal from "../../UI/CommonModal";
import toast from "react-hot-toast";
import axios from "axios";
import Loader from "../../UI/Loader";
import { getErrorMessage } from "../../utils/functions";
const GstDetailsModal = ({ isOpen, onClose, restaurant }) => {
  const [flag, setFlag] = useState(false);
  const [details, setDetails] = useState({
    lgnm: "",
    stj: "",
    dty: "",
    gstin: "",
    nba: [],
    rgdt: "",
    ctb: "",
    pradr: {
      adr: "",
      city: "",
      state: "",
      pin: "",
      country: "",
      lat: "",
      lng: "",
    },
    sts: "",
    tradeNam: "",
    ctj: "",
  });
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (restaurant?.gst) {
      //   alert(restaurant?.gst);
      fetchGstDetails();
    }
  }, [isOpen, restaurant?.gst]);
  const API_KEY = "5c7cbb36f633926b2352e07250ee7c9b";
  const fetchGstDetails = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `https://sheet.gstincheck.co.in/check/${API_KEY}/${restaurant.gst}`
      );
      const data = response.data.data;
      setFlag(response.data.flag);
      if (!response?.data?.flag) {
        setLoading(false);
        onClose();
        toast.error("GST details not available");
        return;
      }
      setDetails(data);
    } catch (error) {
      toast.error(getErrorMessage(error));
      onClose();
    } finally {
      setLoading(false);
    }
  };
  if (!restaurant?.gst) {
    onClose();
    toast.error("GST details not available");
    return null;
  }

  if (loading) {
    return <Loader />;
  }

  return flag ? (
    <div className="space-y-4">
      <div>
        <strong>Legal Name: </strong> {details.lgnm}
      </div>
      <div>
        <strong>GSTIN: </strong> {details.gstin}
      </div>
      <div>
        <strong>Trade Name: </strong> {details.tradeNam}
      </div>
      <div>
        <strong>Constitution: </strong> {details.ctb}
      </div>
      <div>
        <strong>Registration Date: </strong> {details.rgdt}
      </div>
      <div>
        <strong>Status: </strong> {details.sts}
      </div>
      <div>
        <strong>Nature of Business Activities: </strong>{" "}
        {details?.nba?.join(", ")}
      </div>
      <div>
        <strong>Type: </strong> {details.dty}
      </div>
      <div>
        <strong>Principal Address: </strong> {details.pradr.adr}
      </div>
      <div>
        <strong>State Jurisdiction: </strong> {details.stj}
      </div>
      <div>
        <strong>Center Jurisdiction: </strong> {details.ctj}
      </div>
    </div>
  ) : null;
};

export default GstDetailsModal;
