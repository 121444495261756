import React from "react";
import { FaPlus } from "react-icons/fa";
import MyButton from "../../../UI/MyButton";
import { ROLES } from "../../../constants/roles";

const MenuActions = ({ showMenu, totalItems, handleSetShowMenu }) => {
  return (
    <div className="flex justify-end p-4">
      {showMenu ? (
        <MyButton
          className="pl-2 pr-2 p-1 text-white rounded-[10px] bg-slate-800 font-semibold flex justify-center items-center gap-1"
          onClick={handleSetShowMenu}
          allowedRoles={[ROLES.SUPER_ADMIN]}
        >
          View Items
        </MyButton>
      ) : (
        <div className="w-full flex justify-between items-center">
          <p className="ml-3 font-semibold text-lg">
            Total items ({totalItems})
          </p>
          <MyButton
            className="pl-2 pr-2 p-1 text-white rounded-[10px] bg-slate-800 font-semibold flex justify-center items-center gap-1"
            onClick={handleSetShowMenu}
            allowedRoles={[ROLES.SUPER_ADMIN, ROLES.RESTAURANT_ADMIN]}
          >
            <FaPlus fontWeight={"200"} /> Add item
          </MyButton>
        </div>
      )}
    </div>
  );
};

export default MenuActions;
