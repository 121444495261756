import http from "./http";

const notificationApi = "/notification";

export const getAllNotifications = async (query = "") => {
  const res = await http.get(`${notificationApi}/all${query}`);
  return res;
};
export const markAsRead = async () => {
  const res = await http.post(`${notificationApi}/mark-as-read`);
  return res;
};
