import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import Skeleton from "../../../Skeletons/Skeleton";
import ConfirmationModal from "../../../UI/ConfirmationModal";
import imageConstant from "../../../constants/imageConstant";
import {
  deleteMenu,
  getAllFoodCategories,
  getAllMenu,
} from "../../../services/menuApi";
import { getErrorMessage } from "../../../utils/functions";
import CategoryCards from "./CategoryCards";
import FoodCard from "./FoodCard";

const getDefaultCategory = (categoriesLength = 0) => {
  return {
    _id: "All",
    thumbnail: imageConstant.allFoods,
    count: categoriesLength,
  };
};

const MenuItems = ({ setTotalItems, showMenu, totalItems, setShowMenu }) => {
  const [menuItems, setMenuItems] = useState([]);
  const [foodCategories, setFoodCategories] = useState({});
  const [item, setItem] = useState({});
  const [open, setOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(
    getDefaultCategory()
  );
  const [loading, setLoading] = useState(false);

  const mobileView = useSelector((state) => state.auth.mobileView);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await Promise.all([getAllMenu(), getAllFoodCategories()]);
      setMenuItems(res[0]);
      setTotalItems(res[0].length);
      setFoodCategories(res[1]);
    } catch (err) {
      toast.error(getErrorMessage(err));
    } finally {
      setLoading(false);
    }
  };

  const showConfirmationModal = (food) => {
    setOpen(true);
    setItem(food);
  };

  const handleDeleteMenuItem = async () => {
    try {
      setLoading(true);
      await deleteMenu(`?menuId=${item._id}`);
      await fetchData();
      setMenuItems((prevItems) =>
        prevItems.filter((food) => food._id !== item._id)
      );
      toast.success("Item deleted successfully!");
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setOpen(false);
      setLoading(false);
    }
  };

  const handleCategory = (category) => {
    setSelectedCategory(category === selectedCategory ? null : category);
  };

  useEffect(() => {
    if (!selectedCategory) return;

    const query =
      selectedCategory?._id !== "All"
        ? `?category=${selectedCategory?._id}`
        : "";

    setLoading(true);
    getAllMenu(query)
      .then((res) => {
        setMenuItems(res);
        setTotalItems(res.length);
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err.message);
        setLoading(false);
      });
  }, [selectedCategory]);

  return (
    <div>
      <ConfirmationModal
        isOpen={open}
        loading={loading}
        message={"Are you sure you want to delete this item?"}
        onClose={() => setOpen(false)}
        onConfirm={handleDeleteMenuItem}
      />
      <div className="flex lg:ml-[1.2rem] category-card-container gap-3 overflow-auto no-scrollbar lg:h-[12vh] pl-2">
        <div className="flex gap-4 ">
          {/* {!loading && ( */}
          <CategoryCards
            key={0}
            category={getDefaultCategory(foodCategories?.totalItems)}
            onClick={() =>
              handleCategory(getDefaultCategory(foodCategories?.totalItems))
            }
            isActive={selectedCategory?._id === "All"}
          />
          {/* )} */}
          {foodCategories?.categories?.map((category, index) => (
            <CategoryCards
              key={index}
              category={category}
              onClick={() => handleCategory(category)}
              isActive={category === selectedCategory}
            />
          ))}
        </div>
      </div>
      <div className="flex flex-wrap lg:gap-4 lg:p-4 lg:justify-start justify-center ">
        {loading ? (
          <>
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </>
        ) : (
          <>
            {menuItems.map((food, index) => (
              <FoodCard
                food={food}
                key={index}
                showConfirmationModal={showConfirmationModal}
                showMenu={showMenu}
                setShowMenu={setShowMenu}
              />
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default MenuItems;
