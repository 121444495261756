import {
  Box,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Icon,
  Image,
  Text,
} from "@chakra-ui/react";
// Here we have used react-icons package for the icons
import {
  MdDashboard,
  MdGroups,
  MdOutlineMenuBook,
  MdSettings,
} from "react-icons/md";
import { IoRestaurantOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import imageConstant from "../constants/imageConstant";
import { handleNavBar } from "../redux/authSlice";
import { ROLES } from "../constants/roles";

const Page = [
  {
    name: "Dashboard",
    path: "/dashboard",
    icon: MdDashboard,
    label: "Dashboard",
    roles: [ROLES.SUPER_ADMIN],
  },
  // {
  //   name: "CreateMenu",
  //   path: "/menus",
  //   icon: MdOutlineMenuBook,
  //   label: "Menus",
  //   isAdmin: false,
  //   roles: [ROLES.SUPER_ADMIN],
  // },
  // {
  //   name: "Employee",
  //   path: "/restaurant-admins",
  //   icon: MdGroups,
  //   label: "Employee",
  //   isAdmin: false,
  //   roles: [ROLES.SUPER_ADMIN],
  // },
  // {
  //   name: "Tables",
  //   path: "/tables",
  //   icon: MdTableRestaurant,
  //   label: "Tables",
  //   isAdmin: false,
  //   roles: [ROLES.SUPER_ADMIN],
  // },
  {
    name: "Restaurant",
    path: "/restaurants",
    icon: IoRestaurantOutline,
    label: "Restaurant",
    isAdmin: false,
    roles: [ROLES.SUPER_ADMIN],
  },
  {
    name: "Menus",
    path: "/menus",
    icon: MdOutlineMenuBook,
    label: "Menus",
    isAdmin: false,
    roles: [ROLES.SUPER_ADMIN],
  },
  {
    name: "Settings",
    path: "/settings",
    icon: MdSettings,
    label: "Settings",
    isAdmin: false,
    roles: [ROLES.SUPER_ADMIN],
  },
  // {
  //   name: "Employees",
  //   path: "/restaurant-admins",
  //   icon: MdGroups,
  //   label: "Restaurant Admins",
  //   isAdmin: false,
  //   roles: [ROLES.SUPER_ADMIN],
  // },
];

function Sidebar() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { auth } = useSelector((state) => state);
  const { user } = auth;
  const navbarOpened = useSelector((state) => state.auth.navBarOpened);

  const filteredPages = Page.filter(
    (item) => !item.roles || item.roles.includes(user?.role) // If no roles specified or user's role matches any of the required roles
  );

  const SidebarContent = ({ ...props }) => (
    <Box
      top="0"
      left="0"
      h="full"
      // hidden={location.pathname === "/signup" || location.pathname === "/login"}
      pb="10"
      overflowX="hidden"
      overflowY="auto"
      className="bg-primary text-black w-64"
      {...props}
    >
      <Flex px="4" py="5" align="center">
        <Text
          fontSize="2xl"
          ml="2"
          // color={useColorModeValue('brand.500', 'white')}
          fontWeight="semibold"
        >
          <Image
            src={imageConstant.logo}
            alt="logo"
            className="aspect-auto w-32"
          />
        </Text>
      </Flex>
      <Flex
        direction="column"
        as="nav"
        fontSize="md"
        className="flex justify-center items-center"
        aria-label="Main Navigation"
      >
        {filteredPages.map((item, index) => (
          <NavItem item={item} key={index} />
        ))}
      </Flex>
    </Box>
  );

  const NavItem = (props) => {
    const { icon, label, path } = props.item;

    const handleClick = () => {
      if (navbarOpened) {
        dispatch(handleNavBar());
      }
      navigate(path);
    };

    return (
      <Flex
        onClick={handleClick}
        align="center"
        className={`${
          path === location.pathname ? " bg-[#494949]" : ""
        }  text-white font-semibold p-2 w-10/12 rounded`}
        cursor={"pointer"}
      >
        {icon && <Icon mx="2" boxSize="4" as={icon} />}
        {label}
      </Flex>
    );
  };

  return (
    <>
      <SidebarContent display={{ base: "none", md: "unset" }} />
      <Box as="section" className="bg-red-900" minH="100vh">
        <Drawer
          isOpen={navbarOpened}
          onClose={() => dispatch(handleNavBar())}
          placement="top"
        >
          <DrawerOverlay />
          <DrawerContent>
            <SidebarContent borderRight="none" w="full" />
          </DrawerContent>
        </Drawer>
      </Box>
    </>
  );
}
export default Sidebar;
