import {
  Box,
  Button,
  Image,
  Switch,
  Table,
  Tbody,
  Td,
  Text,
  Thead,
  Tr,
} from "@chakra-ui/react";
import TableContainer from "@mui/material/TableContainer";
import React, { useEffect, useState } from "react";
import { FaPlus, FaSearch } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import MyButton from "../../UI/MyButton";
import TabularDialog from "../../UI/TabularDialog";
import { ROLES } from "../../constants/roles";
import {
  getAllRestaurants,
  updateRestaurant,
} from "../../services/restaurantApi";
import toast from "react-hot-toast";
import { getErrorMessage } from "../../utils/functions";
import Loader from "../../UI/Loader";
import { useSelector } from "react-redux";
import RestaurantCard from "./components/RestaurantCard";
import moment from "moment";
import CommonModal from "../../UI/CommonModal";
import GstDetailsModal from "./GstDetailsMode";
function Filters(props) {
  const navigate = useNavigate();
  const handleSetShowRestaurant = () => {
    navigate("/restaurant-admins", {
      state: null,
      replace: true,
    });
  };
  return (
    <div className="flex flex-col md:flex-row justify-between gap-2 pb-2 mb-4 w-full">
      <div className="flex flex-col items-center gap-2 w-full lg:w-3/4 md:w-1/2 lg:flex-row">
        <div className="w-full lg:w-1/2 border border-gray-300 rounded-lg p-1 px-2 flex items-center md:mr-2">
          <FaSearch color="gray" />
          <input
            placeholder="Search restaurants"
            value={props.searchQuery}
            className="w-full outline-none focus:outline-none pl-2"
            onChange={(e) => props.setSearchQuery(e.target.value)}
          />
        </div>

        <div className="flex justify-center items-center gap-2 md:ml-2">
          <Switch
            colorScheme="teal"
            isChecked={props.filters.isVerified}
            onChange={() =>
              props.setFilters({
                ...props.filters,
                isVerified: !props.filters.isVerified,
              })
            }
          />
          <Text>Verified</Text>
          <Switch
            colorScheme="teal"
            isChecked={props.filters.isSubscriptionActive}
            onChange={() =>
              props.setFilters({
                ...props.filters,
                isSubscriptionActive: !props.filters.isSubscriptionActive,
              })
            }
          />
          <Text>Active</Text>
          <Switch
            colorScheme="teal"
            isChecked={props.filters.isFreeTrial}
            onChange={() =>
              props.setFilters({
                ...props.filters,
                isFreeTrial: !props.filters.isFreeTrial,
              })
            }
          />
          <Text>Free trial</Text>
        </div>
      </div>

      <div className="flex md:flex-row justify-end items-center gap-3 w-full lg:1/4 md:w-1/2 mt-2 md:mt-0">
        <MyButton
          className="pl-2 pr-2 p-1 text-white rounded-[10px] bg-slate-800 font-semibold flex justify-center items-center gap-1"
          onClick={handleSetShowRestaurant}
          allowedRoles={[ROLES.SUPER_ADMIN]}
        >
          <FaPlus fontWeight={"200"} /> Add restaurants
        </MyButton>
        <div className="flex flex-col align-end gap-2">
          <p className="text-sm text-gray-500">
            Verified Restaurants: {props.verifiedRestaurantsCount}
          </p>
          <p className="text-sm text-gray-500">
            Active Restaurants: {props.activeRestaurantsCount}
          </p>
        </div>
      </div>
    </div>
  );
}

const Restaurants = () => {
  const [showDetails, setShowDetails] = useState(false);
  const [showOwnerDetails, setShowOwnerDetails] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedOwner, setSelectedOwner] = useState(null);
  const [restaurants, setRestaurants] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [filteredRestaurants, setFilteredRestaurants] = useState([]);
  const [verifiedRestaurantsCount, setVerifiedRestaurantsCount] = useState(0);
  const [activeRestaurantsCount, setActiveRestaurantsCount] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [filters, setFilters] = useState({
    isVerified: false,
    isSubscriptionActive: false,
    isFreeTrial: false,
  });

  const mobileView = useSelector((state) => state.auth.mobileView);
  const user = useSelector((state) => state.auth.user);

  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    fetchAllRestaurants();
    setLoading(false);
  }, []);

  useEffect(() => {
    setLoading(true);
    updateRestaurantCounts();
    setLoading(false);
  }, [restaurants]);

  // useEffect(() => {
  const filteredRestaurants = restaurants.filter((r) => {
    const matchesSearchQuery =
      r.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      r.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
      r.phone.toLowerCase().includes(searchQuery.toLowerCase()) ||
      r.owner?.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      r.owner?.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
      r.owner?.mobileNumber.toLowerCase().includes(searchQuery.toLowerCase());

    const { isVerified, isFreeTrial, isSubscriptionActive } = filters;

    return (
      matchesSearchQuery &&
      (!isVerified || r.isVerified) &&
      (!isSubscriptionActive || r.isSubscriptionActive) &&
      (!isFreeTrial || r.isFreeTrial)
    );
  });

  // setFilteredRestaurants(filteredRestaurants);
  // }, [filters]);

  const fetchAllRestaurants = async () => {
    try {
      setLoading(true);
      const res = await getAllRestaurants();
      setRestaurants(res);
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const handleVerifiedChange = async (restaurantId, isVerified) => {
    try {
      setLoading(true);
      const updatedRestaurant = await updateRestaurant(
        { isVerified },
        restaurantId
      );
      const updatedRestaurants = restaurants.map((restaurant) =>
        restaurant._id === updatedRestaurant._id
          ? updatedRestaurant
          : restaurant
      );
      setRestaurants(updatedRestaurants);
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const handleActiveChange = async (restaurant, attribute, value) => {
    if (
      attribute === "isFreeTrial" &&
      value === true &&
      restaurant.isSubscriptionActive
    ) {
      return toast.error(
        "You cannot start free trial restaurant with active subscription."
      );
    }

    try {
      setLoading(true);
      // if (
      //   !restaurant.isVerified &&
      //   attribute === "isActive" &&
      //   value === true
      // ) {
      //   return toast.error("You cannot active restaurant without verifying.");
      // }
      const updatedRestaurant = await updateRestaurant(
        { [attribute]: value },
        restaurant._id
      );
      const updatedRestaurants = restaurants.map((restaurant) =>
        restaurant._id === updatedRestaurant._id
          ? updatedRestaurant
          : restaurant
      );
      setRestaurants(updatedRestaurants);
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const toggleDetails = (restaurant) => {
    setSelectedRow(restaurant);
    setShowDetails(true);
  };

  const toggleOwnerDetails = (owner) => {
    setSelectedOwner(owner);
    setShowOwnerDetails(true);
  };

  const updateRestaurantCounts = () => {
    // Exclude restaurants owned by users with the role "super_admin"
    const filteredRestaurants = restaurants.filter(
      (restaurant) => restaurant.owner.role !== ROLES.SUPER_ADMIN
    );

    // Calculate counts for the filtered restaurants
    const verifiedCount = filteredRestaurants.filter(
      (restaurant) => restaurant.isVerified
    ).length;
    const activeCount = filteredRestaurants.filter(
      (restaurant) => restaurant.isSubscriptionActive
    ).length;

    setVerifiedRestaurantsCount(verifiedCount);
    setActiveRestaurantsCount(activeCount);
  };

  const editRestaurant = (restaurant) => {
    navigate("/create-restaurant", {
      state: { restaurant, isEdit: true },
      replace: true,
    });
  };

  const [showGstDetailModal, setShowGstDetailModal] = useState(false);
  const [selectedGstRestaurant, setSelectedGstRestaurant] = useState(null);
  const handleCheckGstDetails = (restaurant) => {
    setShowGstDetailModal(true);
    setSelectedGstRestaurant(restaurant);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="p-3">
      <CommonModal
        isOpen={showGstDetailModal}
        onClose={() => setShowGstDetailModal(false)}
        title="GST Details"
      >
        <GstDetailsModal
          isOpen={false}
          onClose={() => setShowGstDetailModal(false)}
          title="GST Details"
          restaurant={selectedGstRestaurant}
        />
      </CommonModal>
      <Filters
        verifiedRestaurantsCount={verifiedRestaurantsCount}
        activeRestaurantsCount={activeRestaurantsCount}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        setFilters={setFilters}
        filters={filters}
      />
      <>
        {mobileView ? (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {filteredRestaurants.map((restaurant) => (
              <RestaurantCard
                key={restaurant._id}
                restaurant={restaurant}
                toggleDetails={toggleDetails}
                toggleOwnerDetails={toggleOwnerDetails}
                editRestaurant={editRestaurant}
                handleActiveChange={handleActiveChange}
                handleVerifiedChange={handleVerifiedChange}
              />
            ))}
          </div>
        ) : (
          <TableContainer>
            <Table variant="striped" colorScheme="gray">
              <Thead>
                <Tr>
                  <Td>Logo</Td>
                  <Td>Name</Td>
                  <Td>Restaurant Email</Td>
                  <Td>Restaurant Number</Td>
                  {/* <Td>Active</Td> */}
                  <Td>Verified</Td>
                  <Td>Free Trial</Td>
                  <Td>Active</Td>
                  <Td>Owner</Td>
                  <Td>Actions</Td>
                </Tr>
              </Thead>
              <Tbody>
                {filteredRestaurants.map((restaurant) => {
                  if (restaurant._id === user?.restaurant?._id) {
                    return null;
                  }
                  return (
                    <Tr key={restaurant._id}>
                      <Td>
                        <Box maxW="50px">
                          <Image src={restaurant.logo} alt="Logo" />
                        </Box>
                      </Td>
                      <Td>{restaurant.name}</Td>
                      <Td>{restaurant.email}</Td>
                      <Td>{restaurant.phone}</Td>

                      <Td>
                        <Switch
                          isChecked={restaurant.isVerified}
                          onChange={(e) =>
                            handleVerifiedChange(
                              restaurant._id,
                              e.target.checked
                            )
                          }
                          colorScheme="teal"
                          size="md"
                        />
                      </Td>
                      <Td>
                        <Switch
                          isChecked={restaurant.isFreeTrial}
                          colorScheme="teal"
                          size="md"
                        />
                      </Td>
                      <Td>
                        <Switch
                          isChecked={
                            restaurant.isSubscriptionActive &&
                            restaurant?.subscriptionDetails?.planEndDate
                              ? moment(
                                  restaurant?.subscriptionDetails?.planEndDate
                                ).isBefore(moment())
                              : false
                          }
                          // onChange={(e) =>
                          //   handleActiveChange(
                          //     restaurant,
                          //     "isSubscriptionActive",
                          //     e.target.checked
                          //   )
                          // }
                          colorScheme="teal"
                          size="md"
                        />
                      </Td>
                      <Td>
                        <Button
                          colorScheme="blue"
                          variant="outline"
                          size={"sm"}
                          onClick={() => toggleOwnerDetails(restaurant?.owner)}
                        >
                          View
                        </Button>
                      </Td>
                      <Td>
                        <div className="flex gap-2 w-full">
                          <Button
                            colorScheme="blue"
                            variant="outline"
                            size={"sm"}
                            onClick={() => handleCheckGstDetails(restaurant)}
                          >
                            Check GST Details
                          </Button>
                          <Button
                            colorScheme="green"
                            variant="outline"
                            size={"sm"}
                            onClick={() => editRestaurant(restaurant)}
                          >
                            Edit
                          </Button>
                          <Button
                            colorScheme="teal"
                            variant="outline"
                            size={"sm"}
                            onClick={() => toggleDetails(restaurant)}
                          >
                            Details
                          </Button>
                        </div>
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </TableContainer>
        )}
        <TabularDialog
          isOpen={showDetails}
          onClose={() => setShowDetails(false)}
          title="Restaurant Details"
          details={selectedRow}
        />

        <TabularDialog
          isOpen={showOwnerDetails}
          onClose={() => setShowOwnerDetails(false)}
          title="Owner Details"
          details={selectedOwner}
        />
      </>
    </div>
  );
};

export default Restaurants;
