import React, { useEffect, useState, useMemo } from "react";
import InputField from "../../UI/InputField";
import Gap from "../../UI/Gap";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import { getAllMenu } from "../../services/userApi";
import Loader from "../../UI/Loader";
import { FaPlus, FaTrash } from "react-icons/fa";
import { menuFields } from "../../constants/constant";
import MenuItems from "./components/MenuItems";
import {
  appendIfDefined,
  createImageFileFromUrl,
  createImagePreview,
  createImagePreviewFromUrl,
  getErrorMessage,
} from "../../utils/functions";
import { MdClose } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import { createMenu, updateMenu } from "../../services/menuApi";
import MenuForm from "./components/MenuForm";
import MenuActions from "./components/MenuActions";

import Skeleton from "../../Skeletons/Skeleton";
import { FolderGoogleDrive } from "mdi-material-ui";
const Menus = ({}) => {
  const [loading, setLoading] = useState(false);
  const [item, setItem] = useState({});
  const [images, setImages] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [showMenu, setShowMenu] = useState(false);
  const [isItemEdit, setIsItemEdit] = useState(false);
  const [quantityOptions, setQuantityOptions] = useState([
    {
      quantity: "",
      price: "",
      quantityUnit: "",
    },
  ]);

  const addQuantityInput = () => {
    setQuantityOptions([
      ...quantityOptions,
      { quantity: "", price: 0, quantityUnit: "" },
    ]);
  };

  const removeQuantityInput = (indexToRemove) => {
    setQuantityOptions((prevQuantityOptions) =>
      prevQuantityOptions.filter((_, index) => index !== indexToRemove)
    );
  };

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.state) {
      const { food, isEdit } = location.state;
      setIsItemEdit(isEdit);
      setItem(food);


      setQuantityOptions(
        food.quantityOptions.length === 0
          ? [{ quantity: "", price: 0, quantityUnit: "" }]
          : food.quantityOptions
      );
      const image = food.thumbnail;
      setImages([image]);
      createImageFileFromUrl(image, food.name).then((imageFile) => {
        setImages([imageFile]);
      });

      const arr = [];
      if (food.thumbnail) {
        arr.push(food.thumbnail);
      }
      if (food?.photos?.length > 0) {
        food?.photos?.forEach((photo) => {
          arr.push(photo);
        });
      }

      const previewArr = [];

      // arr?.forEach((photo) => {
      Promise.all(arr.map((item) => createImagePreviewFromUrl(item))).then(
        (res) => {
          setImagePreviews(res);
        }
      );
      Promise.all(arr.map((item) => createImageFileFromUrl(item))).then(
        (res) => {
          setImages(res);
        }
      );
      // createImagePreviewFromUrl(food.thumbnail).then((previewUrl) => {

      //   previewArr.push(previewUrl);
      // });
      // });
      // setImagePreviews(previewArr);
    }
  }, [location.state]);

  const handleImageUpload = (e) => {
    // Commented out logic for multiple images
    const newImages = [...images, ...e.target.files];
    setImages(newImages);

    // Allowing only one image
    // const newImages = [e.target.files[0]];
    // setImages(newImages);

    const previewArray = newImages.map((image) => {
      return createImagePreview(image);
    });
    setImagePreviews(previewArray);
  };

  const createMenuItem = async (e) => {
    e.preventDefault();
    try {
      if (images.length === 0)
        return toast.error("At least 1 image is required");

      setLoading(true);

      const formData = new FormData();
      let isAnyQuantityOptions = false;
      quantityOptions.forEach((item) => {
        if (!item.quantity || !item.quantityUnit || !item.price) {
          isAnyQuantityOptions = true;
        }
      });

      if (isAnyQuantityOptions) {
        return toast.error("Fill each field of quantity ");
      }

      // Append text fields to FormData
      appendIfDefined(formData, "name", item.name);
      appendIfDefined(formData, "price", item.price);
      appendIfDefined(formData, "variant", item.variant);
      appendIfDefined(formData, "description", item.description);
      appendIfDefined(formData, "kot", item.kot);
      appendIfDefined(formData, "category", item.category);
      appendIfDefined(formData, "quantityUnit", item.quantityUnit);
      appendIfDefined(formData, "isSpecial", item.isSpecial);
      appendIfDefined(formData, "isTopRated", item.isTopRated);
      appendIfDefined(formData, "isActive", item.isActive);
      appendIfDefined(
        formData,
        "quantityOptions",
        JSON.stringify(quantityOptions)
      );

      images.forEach((image, index) => {
        formData.append(`images`, image);
      });

      if (isItemEdit) {
        await updateMenu(formData, `?id=${item._id}`);
        toast.success("Menu updated successfully!");
      } else {
        await createMenu(formData);
        toast.success("Menu created successfully!");
      }

      setItem({});
      setShowMenu(false);
      setImages([]);
      setImagePreviews([]);
      setIsItemEdit(false);
      return;
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const removeImage = (index) => {
    const newImages = [...images];
    const newPreviews = [...imagePreviews];

    newImages.splice(index, 1);
    newPreviews.splice(index, 1);

    setImages(newImages);
    setImagePreviews(newPreviews);
  };

  const handleInputChange = (field, value) => {
    setItem((prevItem) => ({
      ...prevItem,
      [field]: value,
    }));
  };
  const handleSelectChange = (field, selectedValue) => {
    setItem((prevItem) => ({
      ...prevItem,
      [field]: selectedValue.value,
    }));
  };

  const handleCheckboxChange = (field, selectedValue) => {
    setItem((prevItem) => ({
      ...prevItem,
      [field]: selectedValue,
    }));
  };

  const handleSetShowMenu = () => {
    setShowMenu((prev) => !prev);
    navigate(`/menus`, {
      state: null,
      replace: true,
    });
    setItem({});
    setImagePreviews([]);
    setImages([]);
  };

  return (
    <div className="bg-white lg:w-[85vw] overflow-auto">
      <MenuActions
        showMenu={showMenu}
        totalItems={totalItems}
        handleSetShowMenu={handleSetShowMenu}
      />
      {showMenu ? (
        <div className="flex flex-col bg-white">
          <MenuForm
            item={item}
            images={images}
            imagePreviews={imagePreviews}
            handleImageUpload={handleImageUpload}
            removeImage={removeImage}
            handleInputChange={handleInputChange}
            handleSelectChange={handleSelectChange}
            handleCheckboxChange={handleCheckboxChange}
            createMenuItem={createMenuItem}
            isItemEdit={isItemEdit}
            loading={loading}
            quantityOptions={quantityOptions}
            setQuantityOptions={setQuantityOptions}
            addQuantityInput={addQuantityInput}
            removeQuantityInput={removeQuantityInput}
          />
        </div>
      ) : (
        <MenuItems
          totalItems={totalItems}
          setTotalItems={setTotalItems}
          showMenu={showMenu}
          setShowMenu={setShowMenu}
          setLoading={setLoading}
          loading={loading}
        />
      )}
    </div>
  );
};

export default Menus;
