import { Button, CircularProgress } from "@chakra-ui/react";
import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { ROLES } from "../constants/roles";

const MyButton = (props) => {
  const { user } = useSelector((state) => state.auth);

  const isAuthorized =
    props.bypassRoleCheck || user?.role === ROLES.SUPER_ADMIN;

  if (!isAuthorized) {
    return null;
  }

  return (
    <Button {...props} colorScheme="bg-slate-800" disabled={props.loading}>
      {props.loading ? (
        <CircularProgress isIndeterminate size={6} />
      ) : (
        props.children
      )}
    </Button>
  );
};

MyButton.propTypes = {
  loading: PropTypes.bool,
};

export default MyButton;
