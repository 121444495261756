import axios from 'axios';

// Create an instance of Axios
// const baseURL ="https://qr-restaurant-backend.vercel.app/api"
const baseURL = process.env.REACT_APP_BACKEND_ENDPOINT;
const axiosInstance = axios.create({
  baseURL,
});

// Add a request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    // Get the token from your authentication system
    const token = localStorage.getItem('token'); // Replace with your token retrieval method

    // Set the token in the request header
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    // Handle request errors
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    // Modify the response data here if needed
    // For example, extract certain data or transform the response
    return response.data;
  },
  (error) => {
    // Handle response errors
    return Promise.reject(error);
  }
);

export default axiosInstance;
