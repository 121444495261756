import React, { useState } from "react";
import {
  Box,
  Container,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
} from "@chakra-ui/react";
import { toast } from "react-hot-toast";
import { Logo } from "./Logo";
import { PasswordField } from "./PasswordField";
import { login } from "../../services/userApi";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { login as loginReducer } from "../../redux/authSlice";
import imageConstant from "../../constants/imageConstant";
import MyButton from "../../UI/MyButton";
import { getErrorMessage } from "../../utils/functions";

export const Login = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const dispatch = useDispatch();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      if (!email || !password) return toast.error("Please fill all fields");
      setLoading(true);
      const res = await login({ email, password });

      dispatch(
        loginReducer({
          isAuth: true,
          token: res.accessToken,
          user: res.user,
        })
      );
      navigate("/dashboard");
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex justify-between w-full">
      <div className="w-3/5 h-screen hidden lg:flex bg-gradient-to-b from-primary via-tertiary to-secondary flex-col justify-center items-center text-center">
        <img
          src={imageConstant.loginSide}
          className="w-full  h-full object-cover "
          alt="login"
        />
      </div>

      <Container
        maxW="lg"
        py={{
          base: "12",
          md: "24",
        }}
        px={{
          base: "0",
          sm: "8",
        }}
      >
        <Stack spacing="8">
          <Stack spacing="6">
            <div className="flex justify-center">
              <Logo />
            </div>
            <Stack
              spacing={{
                base: "2",
                md: "3",
              }}
              textAlign="center"
            >
              <Heading
                size={{
                  base: "xs",
                  md: "sm",
                }}
              >
                QROP
              </Heading>
            </Stack>
          </Stack>
          <Box
            py={{
              base: "0",
              sm: "8",
            }}
            px={{
              base: "4",
              sm: "10",
            }}
            // bg={{
            //   base: 'transparent',
            //   sm: 'bg-surface',
            // }}
            boxShadow={{
              base: "none",
              sm: "md",
            }}
            borderRadius={{
              base: "none",
              sm: "xl",
            }}
            className="bg-white"
          >
            <Stack spacing="6" as="form" onSubmit={handleLogin}>
              <Stack spacing="5">
                <FormControl>
                  <FormLabel htmlFor="email">Email</FormLabel>
                  <Input
                    id="email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </FormControl>
                <PasswordField
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Stack>
              <Stack spacing="6">
                <MyButton
                  variant="primary"
                  className="bg-primary text-white"
                  type="submit"
                  bypassRoleCheck
                  loading={loading}
                >
                  Sign in
                </MyButton>
                {/* <HStack>
                <Divider />
                <Text fontSize="sm" whiteSpace="nowrap" color="muted">
                  or continue with
                </Text>
                <Divider />
              </HStack> */}
                {/* <OAuthButtonGroup /> */}
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Container>
    </div>
  );
};

export default Login;
