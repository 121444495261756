import React from "react";
import MyButton from "./MyButton";

const CommonModal = ({
  isOpen,
  onClose,
  onConfirm,
  message,
  loading,
  children,
}) => {
  return (
    <>
      {isOpen && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-75 z-50">
          <div className="relative bg-white p-8 rounded-md shadow-md w-3/4 max-w-lg">
            <div className="absolute top-2 right-2">
              <MyButton
                className="px-2 py-1 text-black bg-red-400 rounded-full"
                onClick={onClose}
                bypassRoleCheck
              >
                X
              </MyButton>
            </div>

            {children}
          </div>
        </div>
      )}
    </>
  );
};

export default CommonModal;
